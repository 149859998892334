<template>
  <div class="content">
    <div class="inner">
      <div class="product">
        <div class="product-image">
          <img :src="page.image" :alt="page.name" />
        </div>
        <div class="product-actions">
          <h1>{{ page.name }}</h1>
          <div class="padded" v-if="page.content" v-html="page.content"></div>
          <div v-if="contact_page">
            <page-link :to="contact_page.url + '?item=' + page.name" class="action enquire"
              >Enquire about this product<font-awesome-icon icon="comments"
            /></page-link>
          </div>
          <div v-if="page.configure_online">
            <a
              href="https://configurator.ctbutterfield.com/new?ref=web"
              rel="noreferrer"
              target="_blank"
              class="action configure"
              >Configure this product online<font-awesome-icon icon="drafting-compass"
            /></a>
          </div>
          <div>
            <div class="share">
              Share this: <share-it dense icons :targets="['twitter', 'facebook', 'whatsapp', 'email']" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page", "contact_page"])
  }
}
</script>

<style scoped>
h1 {
  font-size: 4rem;
}

.padded {
  padding: 2rem 0;
}
.product {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
  margin-top: 6rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.product-image {
  text-align: center;
}

.product-image img {
  max-width: 100%;
}

.action,
.share {
  display: inline-block;
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 1rem 2rem;
  margin-bottom: 3rem;
  min-width: 35rem;
  transition: all 0.2s;
}

.action:hover {
  transform: scale(1.05);
}

.action svg {
  float: right;
  margin-top: 0.6rem;
  color: var(--primary-color);
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 3rem;
    margin-top: 3rem;
  }
  .product {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 4rem;
  }
  .action,
  .share {
    width: 100%;
  }
}
</style>
