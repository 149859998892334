<template>
  <div class="icons">
    <div class="inner">
      <div class="grid" v-if="settings">
        <div v-for="icon in settings.highlights" :key="icon._id">
          <div class="icon">
            <div>
              <font-awesome-icon :icon="icon.icon" /><span>{{ icon.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  gap: 5rem;
  margin-bottom: 7rem;
}
.icon {
  border-radius: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}
.icon > div {
  display: block;
  width: 100%;
}
.icon svg {
  font-size: 4rem;
  margin-bottom: 2rem;
  background: #f4f6f6;
  border: 1.5rem solid #f4f6f6;
  border-radius: 100%;
  height: 6rem;
  width: 6rem;
  color: var(--primary-color);
}
.icon span {
  display: block;
}

@media screen and (max-width: 980px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 6rem;
  }
  .grid > div:last-child {
    display: none;
  }
}
</style>
