<template>
  <div class="content">
    <div class="inner">
      <div class="offices" v-if="settings && settings.offices">
        <div class="office" v-for="office in settings.offices" :key="office._id">
          <h3>
            <font-awesome-icon icon="map-marker-alt" />
            {{ office.name }}
          </h3>
          <h4 v-if="office.facilities">{{ office.facilities }}</h4>
          <div class="opening" v-if="office.opening_times">
            <font-awesome-icon icon="clock" />
            {{ office.opening_times }}
          </div>
          <a href class="telephone" v-if="office.telephone">
            <font-awesome-icon icon="phone" />
            {{ office.telephone }}
          </a>
          <a href class="email" v-if="office.email">
            <font-awesome-icon icon="at" />
            {{ office.email }}
          </a>
          <a
            :href="'https://www.google.com/maps/dir/' + encodeURI(office.address)"
            target="_blank"
            rel="noreferrer"
            class="directions"
          >
            <font-awesome-icon icon="location-arrow" /> Directions
          </a>
          <iframe :src="mapUrl[office._id]" style="border: 0" allowfullscreen="" loading="lazy" class="map"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data: function () {
    return {
      mapUrl: {
        LBolm0vOx:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6714.862944855343!2d-1.3130040341737321!3d53.48521197240778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487973e960adabc9%3A0x160c7e6c6a5418d!2sButterfields%20Funeral%20Directors%20%26%20Memorial%20Masons!5e0!3m2!1sen!2suk!4v1638962161138!5m2!1sen!2suk",
        F8Z_LVW4y:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3997.5081574754067!2d-1.4001646623490287!3d53.43391388263684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879770dc05202cd%3A0x250df607c46cb928!2sCT%20Butterfield%20%26%20Sons%20Ltd!5e0!3m2!1sen!2suk!4v1638962234321!5m2!1sen!2suk"
      }
    }
  },
  computed: {
    ...mapState(["page", "settings"])
  }
}
</script>

<style scoped>
h3 {
  font-size: 4rem;
  font-family: "Crimson Pro", Arial;
}
h4 {
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-family: "Crimson Pro", Arial;
}

h4::after {
  display: block;
  content: " ";
  width: 3rem;
  height: 0.4rem;
  background: var(--primary-color);
  margin: 2rem auto;
  border-radius: 5rem;
}

.office h3 svg {
  color: var(--primary-color);
}

.office svg {
  color: var(--secondary-color);
  opacity: 0.8;
  margin-right: 1rem;
}

.offices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  text-align: center;
  margin: 6rem 0;
}

.office a {
  display: block;
}

.map {
  margin-top: 5rem;
  border-radius: 1rem;
  width: 100%;
  height: 45rem;
}

@media screen and (max-width: 980px) {
  .offices {
    grid-template-columns: 1fr;
  }
}
</style>
