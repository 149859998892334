<template>
  <div class="content">
    <div class="inner">
      <span v-if="page.content" v-html="page.content"></span>

      <template v-if="!isRenovationsPage">
        <div class="content-grid" v-for="block in page.content_blocks" :key="block._id">
          <div class="content-grid-one">
            <div v-html="block.text"></div>
          </div>
          <div class="content-grid-two" v-if="block.caption">{{ block.caption }}</div>
          <div class="content-grid-two" v-if="block.image"><img :src="block.image" :alt="block.name" /></div>
        </div>
      </template>

      <div v-if="isRenovationsPage">
        <div class="renovation-grid" v-for="block in page.content_blocks" :key="block._id">
          <div class="renovation-grid-one">
            <p>
              <strong>{{ block.name }}</strong>
            </p>
            <div v-html="block.text"></div>
          </div>
          <div class="renovation-grid-two" v-if="block.image_1"><img :src="block.image_1" :alt="block.name" /></div>
          <div class="renovation-grid-three" v-if="block.image_2"><img :src="block.image_2" :alt="block.name" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapGetters(["isRenovationsPage"]),
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.content >>> h1 {
  margin-top: 6rem;
  font-size: 4rem;
}

.content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "left right";
  gap: 7rem;
  column-gap: 12rem;
  margin: 8rem 0;
}

.content-grid >>> h1 {
  margin-top: 0;
}

.renovation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "one two three";
  gap: 7rem;
  margin: 8rem 0;
}

.renovation-grid-one {
  grid-area: one;
}
.renovation-grid-two {
  grid-area: two;
}
.renovation-grid-three {
  grid-area: three;
}

.renovation-grid img {
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}

.content-grid-one {
  grid-area: left;
}

.content-grid-two {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
  display: grid;
  align-items: center;
  font-family: "Crimson Pro", Arial;
  grid-area: right;
}

.content-grid-two img {
  display: block;
  margin: auto;
}

.content-grid:nth-child(even) {
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "right left";
}

.content-grid-two.image {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.content-grid-two.image img {
  border-radius: 1rem;
  margin: auto;
  display: block;
}

.more {
  display: inline-block;
  background: var(--primary-color);
  padding: 1rem 2rem;
  color: white;
  margin-top: 2rem;
  font-weight: bold;
}

.more svg {
  margin-left: 0.5rem;
}
@media screen and (max-width: 980px) {
  .content >>> h1 {
    font-size: 3rem;
    margin-top: 3rem;
  }
  .content-grid {
    display: block;
    grid-template-columns: 1fr !important;
    grid-template-areas: unset !important;
    margin: 3rem 0;
    gap: 3rem;
    text-align: center;
  }
  .content-grid-two {
    padding-left: 0;
    border-left: 0;
  }
  .content-grid-two {
    padding: 3rem 0 !important;
  }
  .renovation-grid {
    grid-template:
      "one one"
      "two three";
  }
}
</style>
