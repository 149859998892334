<template>
  <div class="breadcrumb">
    <div class="inner">
      <router-link to="/">Home</router-link>

      <template v-for="parent in parents">
        <font-awesome-icon icon="chevron-right" :key="parent.ItemID + '-icon'" />
        <page-link :to="parent.url" :key="parent.ItemID + '-link'">{{ parent.name }}</page-link>
      </template>

      <font-awesome-icon icon="chevron-right" />
      <span>{{ page.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page", "parents"])
  }
}
</script>

<style scoped>
.breadcrumb {
  font-size: 1.2rem;
  margin-top: 3rem;
  margin-bottom: -3rem;
  position: relative;
  z-index: 2;
}

svg {
  color: var(--primary-color);
  margin: 0 1rem;
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 980px) {
  .breadcrumb {
    margin-bottom: 0;
  }
}
</style>
