<template>
  <footer class="footer">
    <div class="inner">
      <div class="grid">
        <div>
          <router-link to="/" class="logo"
            ><img src="/images/memorial-masons.png" alt="Memorials of Elegance"
          /></router-link>
          <p class="strap" v-if="page && settings && settings.footer_summary">
            {{ page.seo ? page.seo : settings.footer_summary }}
          </p>
        </div>
        <div class="quick">
          <div class="heading">Quick Links</div>
          <div class="row" v-for="menu_page in main_pages" :key="menu_page._id">
            <div><font-awesome-icon icon="chevron-right" /></div>
            <div>
              <router-link :to="menu_page.url">{{ menu_page.name }}</router-link>
            </div>
          </div>
          <div class="row ext">
            <div><font-awesome-icon icon="external-link-alt" /></div>
            <div>
              <a href="https://www.ctbutterfield.com/" rel="noreferrer" target="_blank"
                >Looking for funeral services?<br />Visit <span class="underline">www.ctbutterfield.com</span></a
              >
            </div>
          </div>
        </div>
        <template v-if="settings && settings.offices">
          <div :key="office._id" v-for="office in settings.offices">
            <div class="heading">{{ office.name }} Office</div>
            <div class="row address" v-if="office.address">
              <div><font-awesome-icon icon="map-marker-alt" /></div>
              <div v-html="office.address.replace(/[\n\r]/g, '<br>')"></div>
            </div>
            <div class="row telephone" v-if="office.telephone">
              <div><font-awesome-icon icon="mobile-alt" /></div>
              <div>
                <a :href="'tel:' + office.telephone.replace(/ /g, '')">{{ office.telephone }}</a>
              </div>
            </div>
            <div class="row fax" v-if="office.fax">
              <div><font-awesome-icon icon="fax" /></div>
              <div>
                <a :href="'tel:' + office.fax.replace(/ /g, '')">{{ office.fax }}</a>
              </div>
            </div>
            <div class="row email" v-if="office.email">
              <div><font-awesome-icon icon="at" /></div>
              <div>
                <a :href="'mailto:' + office.email">{{ office.email }}</a>
              </div>
            </div>
          </div>
        </template>
        <div class="social">
          <a v-if="settings && settings.facebook" :href="settings.facebook"
            ><font-awesome-icon :icon="['fab', 'facebook']"
          /></a>
          <a v-if="settings && settings.instagram" :href="settings.instagram"
            ><font-awesome-icon :icon="['fab', 'instagram']"
          /></a>
        </div>
      </div>
      <div class="legal">
        Website designed &amp; developed by
        <a href="https://chameleoncreative.marketing" target="_blank" rel="noreferrer">Chameleon Creative</a>
        <div>
          &copy; C T Butterfield &amp; Sons |
          <page-link :to="privacy_page.url" v-if="privacy_page">{{ privacy_page.name }}</page-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings", "main_pages", "privacy_page", "page"])
  }
}
</script>

<style scoped>
.footer {
  padding: 9rem 0;
  padding-bottom: 4rem;
  color: var(--secondary-color);
  font-size: 1.4rem;
  line-height: 1.2;
}

.footer a {
  transition: all 0.3s;
}

.footer a:hover {
  color: var(--primary-color);
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 0.1fr;
  gap: 5rem;
}

.logo {
  display: block;
  width: 17rem;
  margin: auto;
}

.grid > div:first-child {
  padding-right: 7rem;
  text-align: center;
}

.heading {
  font-weight: bold;
  font-family: "Crimson Pro", Arial;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.row {
  display: grid;
  grid-template-columns: 1fr 7fr;
  gap: 1rem;
  margin-bottom: 1.4rem;
}

.row > div:first-child {
  text-align: center;
}

svg {
  opacity: 0.8;
  color: var(--primary-color);
}

.legal {
  margin-top: 10rem;
  opacity: 0.8;
  text-align: center;
}

.strap {
  margin-top: 4rem;
  opacity: 0.8;
  line-height: 1.5;
}
.underline {
  border-bottom: 1px solid var(--primary-color);
}

.ext {
  margin-top: 3rem;
  font-weight: b;
}
.social a {
  display: block;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .grid > div:first-child {
    padding-right: 0;
  }
  .grid {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .row {
    display: block;
  }
  .row > div {
    display: inline;
    margin-right: 1rem;
  }
  .logo {
    margin: auto;
  }
  .legal div {
    display: block;
    margin-top: 1rem;
  }
  .quick {
    display: none;
  }
  .social a {
    display: inline-block;
    margin: 0 1rem;
  }
}

@media screen and (max-width: 500px) {
}
</style>
