<template>
  <div class="nav-wrapper" id="nav">
    <div class="inner">
      <a aria-label="Menu" href class="menu-link" @click.prevent="showMenu = !showMenu">
        <font-awesome-icon icon="bars" /> Menu
      </a>
      <div class="nav-inner" :class="showMenu ? 'show' : ''">
        <ul class="nav" v-if="main_pages">
          <li class="nav-logo">
            <router-link to="/"><img src="/images/memorial-masons-menu.png" alt="Memorials of Elegance" /></router-link>
          </li>
          <li v-for="menu_page in main_pages" :key="menu_page.ItemID" :class="{ sel: selected(menu_page) }">
            <page-link :to="menu_page.url">{{ menu_page.name }}</page-link>
          </li>
          <li class="close">
            <a href @click.prevent="showMenu = !showMenu"><font-awesome-icon icon="times" /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapState(["page", "parents", "main_pages", "keepsakesID"])
  },
  methods: {
    selected(menu_page) {
      if (this.page.ItemID == menu_page.ItemID) return true
      if (this.parents.length && this.parents[0].ItemID == menu_page.ItemID) return true
      if (menu_page.ItemID == this.keepsakesID && this.$route.name == "keepsake-page-by-url") return true

      return false
    }
  }
}
</script>

<style scoped>
.nav-wrapper {
  background: #f4f6f6;
  font-family: "Crimson Pro", Arial;
  color: var(--secondary-color);
  font-size: 2.5rem;
  z-index: 100;
  font-weight: normal;
}
.nav {
  list-style-type: none;
  text-align: center;
}
.nav li {
  display: inline-block;
}
.nav li a {
  display: block;
  padding: 1.6rem 2.5rem;
  transition: all 0.3s;
  font-size: 1.85rem;
}
.nav li a:hover,
.nav li.sel a {
  color: var(--primary-color);
}
.nav li:last-child a {
  border-right: 0;
}
.menu-link,
.nav li.close,
.nav li.nav-logo {
  display: none;
}
.nav-logo img {
  width: 15rem;
}
#nav.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 1500px) {
  .nav li a {
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 980px) {
  .menu-link {
    display: block;
    text-align: center;
    padding: 1.5rem;
  }
  .nav-inner {
    display: block;
    position: fixed;
    top: 0;
    left: -300%;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: var(--secondary-color);
    color: #fff;
    transition: all 0.5s;
    opacity: 0;
  }
  .nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .nav li a {
    font-size: 2.3rem;
    padding: 1.5rem;
  }
  .nav li,
  .nav li.close,
  .nav li.nav-logo {
    display: block;
  }
  .nav li.close a svg {
    font-size: 3rem;
  }
  .nav-inner.show {
    left: 0;
    opacity: 1;
  }

  .nav li a:hover {
    color: inherit;
  }
}

@media screen and (max-width: 400px) {
  .nav li a {
    padding: 1.25rem;
  }
}
</style>
