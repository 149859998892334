<template>
  <div class="banners" :class="{ full: isHomePage, alt: !isHomePage }" id="banners">
    <template v-if="isHomePage">
      <slide-show v-if="page && page.banners">
        <img v-for="image in page.banners" :key="image._id" :src="image.image" :alt="image.alt" />
      </slide-show>
      <div class="inner">
        <div class="caption">{{ page.intro }}</div>
      </div>
    </template>
    <template v-if="!isHomePage">
      <div class="inner">
        <slide-show v-if="page && page.banners">
          <img v-for="image in page.banners" :key="image._id" :src="image.image" :alt="image.alt" />
        </slide-show>
        <div class="caption">{{ page.name }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapGetters(["isHomePage"]),
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.banners {
  position: relative;
  z-index: 1;
  line-height: 0;
}
.banners img {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.banners.alt {
  margin-top: 5rem;
}
.banners.alt img {
  position: relative;
  border-radius: 1rem;
}

.banners.full::before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 31.21%;
  box-shadow: inset 0px -7rem 43rem 7rem var(--secondary-color);
  position: absolute;
  z-index: 10;
  opacity: 0.3;
}
.down {
  position: absolute;
  bottom: 4rem;
  right: 0;
  font-size: 4rem;
  line-height: 1.4;
  text-align: center;
  color: white;
  z-index: 10;
}

.caption {
  position: absolute;
  bottom: 6rem;
  left: 0;
  z-index: 11;
  padding: 2rem 3rem;
  color: var(--secondary-color);
  font-family: "Crimson Pro", Arial;
  font-size: 5rem;
  max-width: 40%;
  line-height: 1.2;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.75);
}

.alt .caption {
  color: white;
  left: 3.5rem;
  bottom: 0.5rem;
  background: none;
}

@media screen and (max-width: 1500px) {
  .caption {
    font-size: 4rem;
  }
}
@media screen and (max-width: 950px) {
  .banners {
    overflow: hidden;
  }
  .banners.alt {
    margin-top: 0;
  }
  .banners.alt img {
    border-radius: 0;
  }
  .full.banners img {
    width: 150%;
    max-width: 150%;
    left: -35%;
  }

  .mouse_scroll {
    display: none !important;
  }
  .alt .caption {
    display: none;
  }
  .caption {
    position: relative;
    border-radius: 0;
    bottom: 0;
    max-width: none;
    text-align: center;
    padding-bottom: 0rem;
    width: 100%;
    color: var(--primary-color);
    background: none;
  }
  .banners.full::before {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .caption {
    position: relative;
    border-radius: 0;
    bottom: 0;
    padding: 4rem;
    padding-bottom: 0;
    font-size: 3.5rem;
  }
  .banners.full::before {
    display: none;
  }
  .banners .inner {
    max-width: 100%;
    width: 100%;
  }
}
</style>
