<template>
  <div class="content">
    <div class="inner">
      <h1>{{ page.name }}</h1>
      <span v-html="page.content"></span>
      <div class="gallery">
        <a
          v-for="image in page.gallery"
          :key="image._id"
          class="photos"
          :href="image.image"
          :style="'background-image: url(' + image.image + ')'"
          :title="image.caption"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { LuminousGallery } from "luminous-lightbox"
import { mapState } from "vuex"

export default {
  props: ["page_loaded"],
  computed: {
    ...mapState(["page"])
  },
  watch: {
    page_loaded: function (new_value) {
      if (new_value)
        this.$nextTick().then(() => {
          new LuminousGallery(
            document.querySelectorAll(".photos"),
            {
              arrowNavigation: true
            },
            {
              caption: function (trigger) {
                return trigger.getAttribute("title")
              }
            }
          )
        })
    }
  }
}
</script>

<style scoped>
h1 {
  margin-top: 6rem;
  font-size: 4rem;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  line-height: 0;
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.gallery a {
  transition: all 0.3s;
  display: block;
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.05);
  height: 25rem;
  border-radius: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.gallery a:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 3rem;
    margin-top: 3rem;
  }
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  .gallery a {
    height: 17.5rem;
  }
}
</style>
