export default {
  methods: {
    removeSpaces: function(string) {
      return string.replace(/ /g, "")
    },
    replaceLineBreaks: function(string) {
      return string.replace(/[\n\r]/g, "<br>")
    },
    urlFormat: function(string) {
      string = string.trim().replace(/ /g, "-")
      string = string.replace(/[^a-zA-Z0-9_-]/g, "")
      string = string.replace(/-+/g, "-")
      return string
    }
  }
}
