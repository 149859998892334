<template>
  <div class="reviews">
    <div class="inner">
      <div class="grid">
        <div><img src="/images/reviews.png" alt="Google Reviews" class="google" /></div>
        <div>
          <slide-show class="slides" v-if="randomTestimonials" @slide-change="pagenumber = $event" ref="slideshow">
            <div class="slide" v-for="slide in randomTestimonials" :key="slide._id">
              <div class="quote">
                <font-awesome-icon icon="quote-left" />{{ slide.quote }}<font-awesome-icon icon="quote-right" />
              </div>
              <div class="person">{{ slide.name }}</div>
            </div>
          </slide-show>
          <div class="pager" v-if="settings && randomTestimonials && randomTestimonials.length > 1">
            <a
              aria-label="Pager"
              v-for="(slide, i) in randomTestimonials"
              :key="i"
              :class="{ sel: i + 1 == pagenumber }"
              @click.prevent="$refs.slideshow.show(i + 1)"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data: function () {
    return { pagenumber: 1 }
  },
  computed: {
    ...mapState(["settings"]),
    randomTestimonials() {
      if (this.settings && this.settings.testimonials && this.settings.testimonials.length) {
        var arr = this.settings.testimonials
        var shuffled = arr.sort(() => 0.5 - Math.random())
        var selected = shuffled.slice(0, 5)
        return selected
      }

      return null
    }
  }
}
</script>

<style scoped>
.reviews {
  padding: 7rem 0;
  background: #f4f6f6;
  line-height: 1.6;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 8fr;
  gap: 8rem;
}

.grid > div:first-child {
  display: flex;
  align-items: center;
}

.quote {
  font-size: 2rem;
}

.person {
  margin-top: 1rem;
  font-size: 2.2rem;
  color: var(--secondary-color);
  font-weight: bold;
  font-family: "Crimson Pro", Arial;
}

.quote svg {
  transform: scale(0.5);
  opacity: 0.3;
  top: -0.25rem;
  position: relative;
}

.pager {
  font-size: 1.2rem;
  margin-top: 2rem;
  text-align: right;
}

.pager a {
  display: inline-block;
  margin-right: 0.5rem;
  opacity: 0.5;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background: var(--secondary-color);
  cursor: pointer;
}
.pager a.sel {
  opacity: 1;
}

@media screen and (max-width: 950px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 5rem;
    text-align: center;
  }
  .google {
    margin: auto;
    display: block;
    width: 12rem;
  }
  .pager {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
}
</style>
