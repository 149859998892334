<template>
  <div class="content">
    <div class="inner">
      <h1 v-if="isACategory">{{ page.name }}</h1>
      <div class="padded" v-if="!isAProduct && page.content" v-html="page.content"></div>
      <div class="related" v-if="isAProduct && filteredProducts.length">You may also like these similar products:</div>
      <div class="products">
        <page-link v-for="product in filteredProducts" :key="product._id" :to="product.url" class="product">
          <img class="product-image" :src="product.image" :alt="product.name" />
          <span class="product-title">{{ product.name }}</span>
          <span class="product-button">View product</span>
        </page-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapGetters(["isACategory", "isAProduct"]),
    ...mapState(["page"]),
    filteredProducts() {
      if (!this.page) return []
      if (!this.page.products) return []

      if (this.isACategory) return this.page.products

      var arr = this.page.products.filter((p) => p.ItemID != this.page.ItemID)
      var shuffled = arr.sort(() => 0.5 - Math.random())
      var selected = shuffled.slice(0, 5)
      return selected
    }
  }
}
</script>

<style scoped>
h1 {
  margin-top: 6rem;
  font-size: 4rem;
}

.padded {
  padding: 2rem 0;
}

.products {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6rem;
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.products a {
  transition: all 0.3s;
  display: block;
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding-bottom: 2rem;
}

.products a:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
}

.products a img {
  height: 25rem;
  margin: 1rem auto;
  display: block;
}

.products a span {
  display: block;
  margin: 0 2rem;
}

.product-title {
  font-family: "Crimson Pro", Arial;
  font-size: 2.5rem;
  line-height: 1.2;
}

.product-button {
  color: var(--primary-color);
  padding-top: 0.5rem;
}

@media screen and (max-width: 1250px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 3rem;
    margin-top: 3rem;
  }
  .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 4rem;
  }
  .products a img {
    height: 15rem;
  }
}
</style>
