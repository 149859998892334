<template>
  <div class="content">
    <div class="inner">
      <h1>{{ page.name }}</h1>
      <span v-html="page.content"></span>
      <div class="categories">
        <page-link v-for="category in page.categories" :to="category.url" :key="category.ItemID" class="category">
          <img class="category-image" :src="category.image" :alt="category.name" />
          <span class="category-title">{{ category.name }}</span>
          <span class="category-button">View collection</span>
        </page-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
h1 {
  margin-top: 6rem;
  font-size: 4rem;
}

.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6rem;
  margin-top: 6rem;
  margin-bottom: 8rem;
}

.categories a {
  transition: all 0.3s;
  display: block;
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding-bottom: 2rem;
}

.categories a:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
}

.categories a img {
  height: 25rem;
  margin: 3rem auto;
  display: block;
}

.categories a span {
  display: block;
  margin: 0 2rem;
}

.category-title {
  font-family: "Crimson Pro", Arial;
  font-size: 2.5rem;
  line-height: 1.2;
}

.category-button {
  color: var(--primary-color);
  padding-top: 0.5rem;
}

@media screen and (max-width: 1250px) {
  .categories {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 3rem;
    margin-top: 3rem;
  }
  .categories {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 4rem;
  }
  .categories a img {
    height: 15rem;
  }
}
</style>
