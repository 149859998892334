<template>
  <div class="accreditations">
    <img
      v-if="settings && settings.accreditations_image"
      :src="settings.accreditations_image"
      alt="Memorial Mason Accreditations"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.accreditations {
  padding: 8rem;
  padding-top: 1rem;
  text-align: center;
}
@media screen and (max-width: 980px) {
  .accreditations {
    padding: 2rem;
  }
}
</style>
