<template>
  <header class="header" id="header">
    <div class="inner">
      <a href="https://configurator.ctbutterfield.com/" rel="noreferrer" target="_blank" class="call designer"
        ><span class="call-icon"><font-awesome-icon icon="drafting-compass" /></span
        ><span class="call-info"
          ><span class="call-info-cta">Try our online</span
          ><span class="call-info-number">Memorial Designer</span></span
        ></a
      >
      <a href="tel:01709571777" class="call"
        ><span class="call-icon"><font-awesome-icon icon="mobile-alt" /></span
        ><span class="call-info"
          ><span class="call-info-cta">Call our team on</span><span class="call-info-number desktop">01709 571 777</span
          ><span class="call-info-number mobile">Call<br />our team</span></span
        ></a
      >
      <router-link to="/" class="logo"
        ><img src="/images/memorial-masons.png" alt="Memorials of Elegance"
      /></router-link>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.header {
  padding: 1rem 0;
  width: 100%;
  z-index: 2;
  font-weight: 300;
  background: white;
}
.logo {
  margin: auto;
  width: 12rem;
  display: block;
}
.call {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 2.5rem;
}
.designer {
  left: 0;
  right: auto;
}

.call-icon,
.call-info {
  display: inline-block;
}
.call-icon {
  margin-right: 1.5rem;
  position: relative;
  top: -0.8rem;
  font-size: 2.5rem;
  color: var(--primary-color);
}
.call-info-number {
  font-weight: bold;
  font-family: "Crimson Pro", Arial;
}
.call-info-cta {
  display: block;
  opacity: 0.8;
  font-size: 1.6rem;
}

.mobile {
  display: none !important;
}
@media screen and (max-width: 850px) {
  .call {
    top: 3rem;
    right: 0;
    transform: none;
    text-align: center;
    margin: 0 0%;
    width: 10rem;
  }
  .call.designer {
    left: 0;
    right: auto;
  }
  .call span {
    display: block;
  }
  .call-icon {
    margin-right: 0rem;
    opacity: 0.6;
  }
  .call-info-number {
    font-size: 2.2rem;
    line-height: 1;
    font-weight: normal;
  }
  .call-info-cta,
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 11rem;
  }
  .call-info-number {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 330px) {
  .logo {
    width: 8rem;
  }
}
</style>
