<template>
  <div class="inner" id="contact-form">
    <div class="form">
      <div class="form-head">
        <h2><font-awesome-icon icon="comments" /> {{ page.form_title }}</h2>
        <p>
          {{ page.form_description }}
        </p>
      </div>
      <div class="form-body">
        <div class="form-row">
          <input class="half txt" placeholder="Name" type="text" v-model="form.name" />
          <input class="half txt" placeholder="Email" type="email" v-model="form.email" />
        </div>
        <div class="form-row">
          <input class="half txt" placeholder="Telephone" type="tel" v-model="form.tel" />
          <input class="half txt" placeholder="Preferred contact method &amp; time" type="text" v-model="form.time" />
        </div>
        <div class="form-row">
          <textarea class="txt" placeholder="Message" v-model="form.message"></textarea>
        </div>
        <div class="form-row">
          <span class="success" v-show="emailsuccess">Thanks, your request has been sent!</span>
          <span class="error" v-show="emailerror">{{ errormessage }}</span>
          <button @click="sendEmail">Send message</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api"

import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  },
  data: function () {
    return {
      emailsuccess: false,
      emailerror: false,
      errormessage: "",
      form: {},
      initial_values: {}
    }
  },
  mounted: function () {
    this.form = JSON.parse(JSON.stringify(this.initial_values))

    if (this.$route.query.item) {
      this.form.message = "Enquiry regarding " + this.$route.query.item

      this.$nextTick().then(() => {
        var element = document.getElementById("contact-form")
        element.scrollIntoView()
      })
    }
  },
  methods: {
    sendEmail: function () {
      this.emailsuccess = false
      this.emailerror = false

      if (!this.form.name || !this.form.email || !this.form.message) {
        this.errormessage = "Please fill in all required fields, thanks!"
        this.emailerror = true
        return false
      }

      var content = `The following message has been sent from the Memorials of Elegance website:<br /><br />`

      content += `Name: ${this.form.name}<br />
                  Email: ${this.form.email}<br />`

      if (this.form.tel) content += `Telephone: ${this.form.tel}<br />`
      if (this.form.time) content += `Preferred contact method & time: ${this.form.time}<br />`

      content += `Message: ${this.form.message}<br />`

      api
        .sendEmail("Memorials of Elegance Enquiry", content)
        .then(() => {
          this.emailsuccess = true
          this.form = JSON.parse(JSON.stringify(this.initial_values))
        })
        .catch(() => {
          this.errormessage = "There has been an error submitting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.form {
  margin-bottom: 6rem;
  background: #f4f6f6;
  border-radius: 1rem;
  text-align: center;
}

h2 {
  font-size: 4rem;
  font-family: "Crimson Pro", Arial;
  margin-bottom: 2rem;
}

h2 svg {
  margin-right: 1rem;
  color: var(--primary-color);
}

.form-head {
  padding-top: 5rem;
}

.form-head p {
  max-width: 80rem;
  margin: auto;
  margin-bottom: 3rem;
}

.form-body {
  padding: 0rem 6rem 6rem 6rem;
}

.txt {
  border: 0;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  width: 40%;
  border-radius: 1rem;
  margin: 1rem;
}

textarea.txt {
  width: calc(80% + 2rem);
  height: 8rem;
}

button {
  background: var(--primary-color);
  border: 0;
  color: white;
  font-family: "Crimson Pro", Arial;
  padding: 1rem 3rem;
  font-size: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.success {
  display: block;
  margin-bottom: 2rem;
}

@media screen and (max-width: 980px) {
  .txt,
  textarea.txt {
    width: 100%;
    margin: 1rem auto;
  }
  .form-body {
    padding: 0 2rem 4rem 2rem;
  }
}
</style>
