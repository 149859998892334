<template>
  <div class="blocks">
    <div class="inner">
      <div class="grid">
        <page-link
          v-for="block in page.blocks"
          :key="block._id"
          :style="'background-image: url(' + block.image + ')'"
          class="keepsakes"
          :to="block.url"
          ><span class="label-wrapper"
            ><span class="label-inner"
              >{{ block.name }}<span class="label-sub">{{ block.description }}</span></span
            ></span
          ></page-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.blocks {
  margin: 3rem 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem;
  margin-bottom: 7rem;
}

.small .grid {
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}

.grid img {
  width: 100%;
}

.blocks a {
  min-height: 40rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: grid;
  align-items: flex-end;
  box-shadow: 10px 3px 15px -1px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 1rem;
  transition: all 0.2s;
}

.small.blocks a {
  min-height: 25rem;
}
.small.blocks a:last-child {
  display: none;
}

.blocks a.keepsakes,
.blocks a.rennovations {
  background-position: bottom;
}

.blocks a::before {
  display: block;
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.blocks a:hover {
  transform: scale(1.03);
  filter: brightness(1.05);
  box-shadow: 10px 3px 15px -1px rgba(0, 0, 0, 0);
}

.label-wrapper {
  display: block;
  width: 100%;
}

.label-inner {
  display: inline-block;
  padding: 2rem 3rem;
  font-family: "Crimson Pro", Arial;
  font-size: 2.5rem;
  font-weight: bold;
  background: white;
  border-top-right-radius: 1rem;
}

.small .label-inner {
  width: 100%;
  border-top-right-radius: 0;
  font-size: 2rem;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.9);
}

.label-sub {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  opacity: 0.85;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.small .label-sub {
  display: none;
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .blocks a {
    min-height: 35rem;
  }
  .label-inner {
    font-size: 2.5rem !important;
  }

  .label-sub {
    font-size: 2rem !important;
  }
}
</style>
