export default {
  methods: {
    numberofpages: function(array, page_size) {
      return Math.ceil(array.length / page_size)
    },
    pageSlice: function(array, page_size, page_number) {
      --page_number
      return array.slice(page_number * page_size, (page_number + 1) * page_size)
    }
  }
}
