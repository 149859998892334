<template>
  <div>
    <header-elem />
    <nav-elem />
    <breadcrumb-elem v-if="hasBreadcrumb" />

    <banners-elem v-if="page.banners" />

    <content-elem v-if="page.content_blocks" />

    <product-elem v-if="isAProduct" />

    <products-elem v-if="isACategory || isAProduct" />

    <categories-elem v-if="isTopParent" />

    <gallery-elem v-if="hasGallery" :page_loaded="page_loaded" />

    <contact-elem v-if="isContactPage" />

    <contact-form v-if="isContactPage" />

    <blocks-elem v-if="isHomePage" />

    <!-- Obituaries / individual obituary only -->
    <page-obituaries :obits="obits" v-if="page.ItemID == $store.state.obitID" />
    <page-obituary :page="page" v-if="page.ItemType == 'page.obituaries'" />

    <accreditations-elem />

    <latest-obituaries :obits="latest_obits" v-if="isHomePage" />

    <icons-elem />
    <reviews-elem />
    <footer-elem />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import BannersElem from "@/components/Banners.vue"
import NavElem from "@/components/Nav.vue"
import ContentElem from "@/components/Content.vue"
import BlocksElem from "@/components/Blocks.vue"
import FooterElem from "@/components/Footer.vue"
import ReviewsElem from "@/components/Reviews.vue"
import AccreditationsElem from "@/components/Accreditations.vue"
import LatestObituaries from "@/components/LatestObituaries.vue"
import IconsElem from "@/components/Icons.vue"
import ContactElem from "@/components/Contact.vue"
import ContactForm from "@/components/ContactForm.vue"
import GalleryElem from "@/components/Gallery.vue"
import CategoriesElem from "@/components/Categories.vue"
import ProductsElem from "@/components/Products.vue"
import BreadcrumbElem from "@/components/Breadcrumb.vue"
import ProductElem from "@/components/Product.vue"
import PageObituaries from "@/components/Obituaries.vue"
import PageObituary from "@/components/Obituary.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    BannersElem,
    NavElem,
    ContentElem,
    BlocksElem,
    FooterElem,
    ReviewsElem,
    AccreditationsElem,
    IconsElem,
    ContactElem,
    ContactForm,
    GalleryElem,
    CategoriesElem,
    ProductsElem,
    BreadcrumbElem,
    ProductElem,
    LatestObituaries,
    PageObituaries,
    PageObituary
  },
  data() {
    return { page_loaded: false, obits: [], latest_obits: [] }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters([
      "isHomePage",
      "isACategory",
      "isAProduct",
      "isTopParent",
      "hasBreadcrumb",
      "hasGallery",
      "isExamplePage",
      "isContactPage",
      "isKeepsakesPage"
    ]),
    isObit: function () {
      if (!this.page || !this.page.ItemID) return false
      var obitID = this.$store.state.obitID
      return this.page.ItemType == "page.obituaries" || this.page.ItemID == obitID
    }
  },
  metaInfo() {
    return {
      title: this.page.title || "Memorials of Elegance",
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function () {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store
    var state = store.state

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "keepsake-page-by-url") promises.push(api.getPageByURL(url, "ctbutterfield"))
    if (route.name == "root") promises.push(api.getHomePage())

    if (route.name == "obit") {
      promises.push(
        api.getObitPageByItemID(route.params.itemid).then((obit) => {
          if (!obit || !obit.ItemID) this.page = { main_text: "Sorry, this page cannot be found!" }
          obit.posts = []
          if (obit.candles && obit.candles.length) {
            var i = 0
            obit.candles.forEach((candle) => {
              candle._type = "candle"
              candle._index = i
              i++
            })
            obit.posts = obit.posts.concat(obit.candles)
          }
          if (obit.messages && obit.messages.length) {
            obit.messages.forEach((message) => {
              message._type = "message"
            })
            obit.posts = obit.posts.concat(obit.messages)
          }

          obit.posts.sort((a, b) => (a._timestamp > b._timestamp ? 1 : -1))
          return Promise.resolve(obit)
        })
      )
      promises.push(
        api.getObitPageByItemID(state.obitID).then((page) => {
          if (!page || !page.ItemID) this.page = { main_text: "Sorry, this page cannot be found!" }
          return Promise.resolve(page)
        })
      )
    }

    promises.push(
      store.dispatch("getObits").then(() => {
        this.latest_obits = state.latest_obits
        this.obits = state.obits
      })
    )

    Promise.all(promises).then((vals) => {
      if (route.name == "obit") {
        var fullpage = Object.assign(vals[1], vals[0])
        this.page = fullpage
        this.page.title = this.page.name
        this.page.meta_desc = this.page.date_of_birth + " to " + this.page.date_of_death
      }

      var returnedpage = vals[0]
      store.commit("setPage", returnedpage)

      store.dispatch("getParent")

      if (!returnedpage || !returnedpage.ItemID)
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

      if (this.isTopParent && !this.isKeepsakesPage)
        api
          .getPages("image", "page.categories=@" + returnedpage.ItemID)
          .then((categories) => this.$set(returnedpage, "categories", categories))

      if (this.isKeepsakesPage)
        api.getPages("image", "page.sub=@cWPJJ986d", "", "", "", "ctbutterfield").then((categories) => {
          categories.forEach((c) => (c.url = "keepsakes/" + c.url))
          this.$set(returnedpage, "categories", categories)
        })

      if (this.isACategory)
        api
          .getPages("image", "page.products=@" + returnedpage.ItemID)
          .then((products) => this.$set(returnedpage, "products", products))

      if (this.isAProduct)
        api
          .getPages("image", "page.products=@" + returnedpage.ParentID)
          .then((products) => this.$set(returnedpage, "products", products))

      this.$nextTick().then(() => {
        this.page_loaded = true
        this.$nextTick().then(() => {
          /*window.onscroll = function () {
            var nav = document.getElementById("nav")
            var banners = document.getElementById("banners")
            if (banners) {
              if (window.pageYOffset > banners.offsetHeight) {
                nav.classList.add("fixed")
              } else {
                nav.classList.remove("fixed")
              }
            }
          }*/
        })
      })
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
  }
}
</script>
